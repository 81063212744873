import AppParticles from "./components/Particles/Particles";
import "./App.scss";
import Card from "./components/Card/Card";

const App = () => {
	return (
		<>
			<AppParticles />
			<div className="content-container">
				<div className="intro-container">
					<div className="title-text-container">
						<div className="forabt-title">For A Better Tomorrow</div>
						<div className="subtitle">Your Partner In Innovative Solutions</div>
					</div>
				</div>
				<div className="bottom-container">
					{/* <Card
            title="Expertise"
            bodyTitle="Premium Software Solutions"
            bodyText="&nbsp;&nbsp;&nbsp;&nbsp;As seasoned executives in the mortgage and technology sectors, we specialize in architecting cutting-edge, full-stack software solutions tailored for the mortgage industry leveraging advanced AWS deployments to optimize operational efficiency."
            // buttonText="test"
          /> */}
					<Card
						title="Consulting"
						// bodyTitle="Consulting"
						bodyText="&nbsp;&nbsp;&nbsp;&nbsp;Organizations solve problems with technology, and organizations have problems with technology. We help them deal with both. Our service is understanding your problem and its context, and providing solutions through researched decomposition."
						// phone="+1-626-241-2286"
						email="upa@4abt.com"
						// buttonText="test"
					/>
					{/* <Card
            title="Enquire"
            bodyTitle="Get In Touch Today"
            phone="+1-626-241-2286"
            email="enquire@4abt.com"
          /> */}
				</div>
			</div>
		</>
	);
};

export default App;
