import React from "react";
import "./Card.scss";

const Card = ({
	topImage,
	title,
	bodyTitle,
	bodyText,
	buttonText,
	phone,
	email,
}: any) => {
	return (
		<div className="card-container">
			<div
				className="top-half"
				// style={{ backgroundImage: `url(${topImage})` }}
			>
				<h2 className="title">{title}</h2>
			</div>
			<div className="bottom-half">
				{bodyTitle && <h3>{bodyTitle}</h3>}
				<p>{bodyText}</p>
				{buttonText != null ? <button>{buttonText}</button> : null}
				{phone != null ? (
					<a
						href={`tel:${phone.replace(/-/g, "")}`}
						style={{ marginTop: "auto" }}>
						<button>{`Text: ${phone}`}</button>
					</a>
				) : null}
				{email != null ? (
					<a href={`mailto:${email}`}>
						<button>{`Email: ${email}`}</button>
					</a>
				) : null}
			</div>
		</div>
	);
};

export default Card;
